'use client';

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Tooltip,
  Typography,
  MenuItem as MenuItemMui,
  Select,
} from '@mui/material';
import type { Project, Role, Viewer } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useContext, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import { Icon } from '@/components/shared/icon';
import { ThemeContext } from '@/components/theme';
import { UserCircle } from '@/components/users/userBadge';
import { rolesComparer } from '@/configs/roles';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

export const MenuItem = ({
  href,
  title,
  iconClass,
  testId,
  disabled,
}: {
  title: string;
  href: string;
  iconClass: string;
  testId?: string;
  disabled?: boolean;
}) => {
  const pathname = usePathname();
  const selected = pathname.startsWith(href);

  return disabled ? (
    <Box
      className={`${classNames(
        'slide-left',
        {}
      )} w-[60px] h-[60px] cursor-default flex items-center justify-middle`}
    >
      <i
        className={`${iconClass} w-[24px] h-[24px] ml-[18px] text-[#a1a1aa]`}
      />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0 text-[#a1a1aa]'
        )}
      >
        {title}
      </Typography>
    </Box>
  ) : (
    <Link
      href={href}
      data-testid={testId}
      className={classNames('slide-left', {
        'bg-white': selected,
        'text-[var(--mui-palette-background-dark)]': selected,
        'hover:bg-[rgba(255,255,255,0.10)]': !selected,
      })}
    >
      <i className={`${iconClass} w-[24px] h-[24px] ml-[18px]`} />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0',
          {
            'text-[var(--mui-palette-background-dark)]': selected,
            'text-white': !selected,
          }
        )}
      >
        {title}
      </Typography>
    </Link>
  );
};

const ActionIcon = ({
  title,
  isActive,
  iconClass,
  onClick,
}: {
  title: string;
  isActive: boolean;
  iconClass: string;
  onClick: () => void;
}) => {
  return (
    <Tooltip
      title={title}
      className='mr-[12px]'
      PopperProps={{ disablePortal: true }}
    >
      <Icon
        sx={{
          backgroundColor: isActive
            ? 'white'
            : 'var(--mui-palette-background-dark)',

          '&:hover': {
            backgroundColor: isActive ? undefined : 'rgba(255, 255, 255, 0.10)',
          },

          '& i': {
            color: isActive ? 'var(--mui-palette-background-dark)' : 'white',
          },
        }}
        iconClass={iconClass}
        isActive={isActive}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export const NavigationActions = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <Box className='slide-left collapse opacity-0 absolute left-[24px] right-[24px] bottom-[24px] '>
      <Stack
        direction='row'
        className='w-[257px]'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack direction='row'>
          <ActionIcon
            title='Light mode'
            isActive={!isDarkMode}
            iconClass='material-symbols-light-mode-outline-rounded'
            onClick={() => changeTheme('light')}
          />
          <ActionIcon
            title='Dark mode'
            isActive={isDarkMode}
            iconClass='material-symbols-bedtime-outline-rounded'
            onClick={() => changeTheme('dark')}
          />
        </Stack>
        <Button
          variant='outlined'
          sx={{
            display: 'none',
            borderColor: 'white',
            background: 'transparent',
            color: 'white',
          }}
        >
          <i className='material-symbols-help-outline-rounded w-[20px] h-[20px] mr-[8px]' />
          Eon Docs
        </Button>
      </Stack>
    </Box>
  );
};

export const UserBox = ({
  setForceOpen,
}: {
  setForceOpen: (open: boolean) => void;
}) => {
  const dal = useDAL();
  const router = useRouter();
  const { user, currentProjectId } = useUser();
  const { rolesMap } = useRoles();
  const [mode, setMode] = useState('default');

  const { body: projects } = dal.projects.list();

  const name = user?.givenName || user?.email || '';

  const hasAccountRoles = Object.keys(user?.roleToProjectsMappings || {}).some(
    (roleId) => {
      const role = rolesMap[roleId];
      return role && !role.isProjectRole;
    }
  );

  return (
    <Box
      className='slide-left collapse opacity-0 absolute top-[12px] left-[24px] right-[24px] rounded-[8px] z-5'
      sx={(theme) => ({
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)',
        background:
          mode === 'default' || theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.10)'
            : 'white',
      })}
    >
      <Stack className='w-[257px] pl-[13px] pr-[16px] py-[16px]' gap='16px'>
        {mode === 'default' && (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Stack direction='row' alignItems='center'>
                <UserCircle name={name} />
                <Typography className='font-semibold text-white ml-[12px] capitalize'>
                  {name}
                </Typography>
              </Stack>
              <OptionMenu
                iconClassName='text-white'
                iconButtonProps={{
                  sx: {
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.10) !important',
                    },
                  },
                }}
                icon='material-symbols-more-horiz'
                options={['Logout']}
                onOptionSelected={(option) => {
                  if (option === 'Logout') {
                    router.push('/logout');
                  }
                }}
              />
            </Stack>

            <Tooltip
              title='Current project and role'
              PopperProps={{ disablePortal: true }}
            >
              <Stack
                direction='row'
                className='rounded-[4px] p-[12px] cursor-pointer'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  background: 'rgba(255, 255, 255, 0.05)',

                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.10)',
                  },
                }}
                onClick={() => {
                  setMode('change-scope');
                  setForceOpen(true);
                }}
              >
                <Stack>
                  <Typography className='text-white'>
                    {
                      projects?.projects?.find((x) => x.id === currentProjectId)
                        ?.name
                    }
                  </Typography>
                  <Typography
                    className='text-white font-medium mt-[4px]'
                    variant='subtitle1'
                  >
                    {user?.selectedRole?.name}
                  </Typography>
                </Stack>
                <i className='material-symbols-arrow-drop-down-rounded text-white w-[24px] h-[24px]' />
              </Stack>
            </Tooltip>

            {hasAccountRoles && (
              <>
                <Divider
                  sx={{
                    borderColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                />
                <Link href='/global-settings' className='h-[36px]'>
                  <Button
                    className='mx-auto'
                    sx={{
                      '&:not(.Mui-disabled):hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.10)',
                      },
                    }}
                  >
                    <Stack
                      gap='8px'
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <i className='material-symbols-settings-outline text-white w-[20px] h-[20px]' />
                      <Typography className='text-white font-medium'>
                        Global Settings
                      </Typography>
                    </Stack>
                  </Button>
                </Link>
              </>
            )}
          </>
        )}
        {mode === 'change-scope' && (
          <ChangeScope
            projects={projects?.projects || []}
            onCancel={() => {
              setMode('default');
              setForceOpen(false);
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

function getAvailableRoles(
  roleToProjectsMappings: Viewer['roleToProjectsMappings'] | undefined,
  rolesMap: Record<string, Role>,
  selectedProjectId: string | null
) {
  return Object.entries(roleToProjectsMappings || {})
    .reduce<Role[]>((agg, entry) => {
      const role = rolesMap[entry[0]];
      if (role) {
        if (!role.isProjectRole) {
          agg.push(role);
        } else if (entry[1]?.ids?.includes(selectedProjectId || '')) {
          agg.push(role);
        }
      }

      return agg;
    }, [])
    .sort(rolesComparer);
}

const ChangeScope = ({
  onCancel,
  projects,
}: {
  onCancel: () => void;
  projects: Project[];
}) => {
  const { user, currentProjectId, changeProject, changeRole } = useUser();
  const { rolesMap } = useRoles();

  const [selectedProjectId, setSelectedProjectId] = useState(currentProjectId);
  const [selectedRole, setSelectedRole] = useState(user?.selectedRole);
  const [isChanging, setIsChanging] = useState(false);

  const availableRoles = getAvailableRoles(
    user?.roleToProjectsMappings,
    rolesMap,
    selectedProjectId
  );

  const hasAdminRole = availableRoles.some((x) => !x.isProjectRole);

  const availableProjects = hasAdminRole
    ? projects
    : Array.from(
        Object.values(user?.roleToProjectsMappings || {}).reduce<Project[]>(
          (agg, value) => {
            (value?.ids || []).forEach((id) => {
              const project = projects.find((x) => x.id === id);
              if (project) {
                agg.push(project);
              }
            });

            return agg;
          },
          []
        )
      );

  return (
    <>
      <FormControl size='small' className='flex-1'>
        <FormLabel>Select project</FormLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          size='small'
          value={selectedProjectId}
          onChange={(event) => {
            setSelectedProjectId(event.target.value);
            const availableRoles = getAvailableRoles(
              user?.roleToProjectsMappings,
              rolesMap,
              event.target.value
            );
            if (availableRoles.every((x) => x.id !== selectedRole?.id)) {
              setSelectedRole(availableRoles[0]);
            }
          }}
        >
          {availableProjects
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((project) => (
              <MenuItemMui key={project.id} value={project.id}>
                {project.name}
              </MenuItemMui>
            ))}
        </Select>
      </FormControl>
      <FormControl size='small' className='flex-1'>
        <FormLabel>Select role</FormLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          size='small'
          value={selectedRole?.id || ''}
          onChange={(event) => setSelectedRole(rolesMap[event.target.value])}
        >
          {availableRoles.map((role) => (
            <MenuItemMui key={role.id} value={role.id}>
              {role.name}
            </MenuItemMui>
          ))}
        </Select>
      </FormControl>
      <Stack direction='row' justifyContent='end'>
        <Button className='mr-[12px]' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant='outlined'
          disabled={
            isChanging ||
            (selectedRole?.id === user?.selectedRole.id &&
              selectedProjectId === currentProjectId)
          }
          onClick={() => {
            setIsChanging(true);
            void Promise.resolve()
              .then(async () => {
                if (
                  selectedProjectId &&
                  selectedProjectId !== currentProjectId
                ) {
                  await changeProject(selectedProjectId);
                }
              })
              .then(async () => {
                if (selectedRole && selectedRole.id !== user?.selectedRole.id) {
                  await changeRole(selectedRole.id);
                }
              })
              .then(
                () => window.location.reload(),
                () => window.location.reload()
              );
          }}
        >
          Change
        </Button>
      </Stack>
    </>
  );
};
