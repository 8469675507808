import { Button, OutlinedInput, Stack, Typography } from '@mui/material';

import type { StepProps } from '@/components/wizard/StepProps';

import type { UpsertServiceAccountState } from './upsertServiceAccountFlow';

export const ServiceAccountsTokenReveal = (
  props: StepProps<UpsertServiceAccountState>
) => {
  return (
    <Stack
      sx={{ height: '100%' }}
      alignItems='center'
      justifyContent='center'
      gap='24px'
    >
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Your service account has been created.
      </Typography>
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Here is your client ID:
      </Typography>
      <OutlinedInput
        sx={{
          width: '655px',
          padding: '8px',
        }}
        value={props.state.clientId}
      />
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Here is your client secret:
      </Typography>
      <OutlinedInput
        sx={{
          width: '655px',
          padding: '8px',
        }}
        value={props.state.clientSecret}
      />
      <div>
        <Button
          sx={{ width: '200px' }}
          variant='contained'
          onClick={props.finish}
        >
          Finish
          <i className='material-symbols-arrow-right-alt ml-[8px]' />
        </Button>
      </div>
    </Stack>
  );
};
