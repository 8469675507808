import { Typography } from '@mui/material';
import { InventoryResourceBackupStatusEnum } from '@repo/api-gw-sdk';

import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import {
  StringOperator,
  type CombineCondition,
  type StringCondition,
} from '@/types/advanceFilter';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';

import { InventoryList } from '../../inventory/[[...ids]]/inventoryListTable';

export const BackupPolicyResourcesList = (
  props: StepProps<BackupPolicyEditState>
) => {
  let baseCondition = {
    conditions: [
      props.state.condition,
      {
        type: 'String',
        property: 'backupStatus',
        operator: StringOperator.NotIn,
        value: [
          InventoryResourceBackupStatusEnum.ExcludedFromBackup,
          InventoryResourceBackupStatusEnum.InitialClassification,
          InventoryResourceBackupStatusEnum.Terminated,
          InventoryResourceBackupStatusEnum.Disconnected,
        ],
      },
    ],
    operator: 'And',
    type: 'Combine',
  } as CombineCondition;

  if (props.state.include?.length) {
    baseCondition = {
      type: 'Combine',
      operator: 'Or',
      conditions: [
        props.state.condition,
        ...props.state.include.map(
          (id) =>
            ({
              type: 'String',
              operator: StringOperator.Equals,
              property: 'resourceId',
              value: [id],
            }) as StringCondition
        ),
      ],
    } as CombineCondition;
  }

  if (props.state.exclude?.length) {
    const exclude = {
      type: 'Combine',
      operator: 'And',
      conditions: props.state.exclude.map(
        (id) =>
          ({
            type: 'String',
            operator: StringOperator.NotEquals,
            property: 'resourceId',
            value: [id],
          }) as StringCondition
      ),
    } as CombineCondition;

    baseCondition = {
      type: 'Combine',
      operator: 'And',
      conditions: [baseCondition, exclude],
    } as CombineCondition;
  }

  return (
    <>
      <StepContainer
        stepperLabels={props.stepperLabels}
        stepperIndex={1}
        onBackClick={props.back}
        canGoNext={() => true}
        onNextClick={() => {
          props.setNextStep(
            props.currentStep.next?.find(
              (s) => s.name === 'Frequency and retention'
            )
          );
        }}
        sx={{
          '&': { p: '12px', pt: '0px' },
        }}
      >
        <Typography className='text-center leading-5 my-[20px]'>
          These are the resources that meet the conditions you set. Review the
          list to make sure it contains the resources you expected.
        </Typography>
        <InventoryList disableSelection baseFilter={baseCondition} />
      </StepContainer>
    </>
  );
};
