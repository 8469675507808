import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { useEffect } from 'react';

import { OptionListItem } from '@/components/shared/optionListItem';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

export interface RestoreBucketState {
  restoreBucketName?: string;
  restoreBucketNameOverride?: string;
  restoreBucketMode: 'select' | 'manual';
  restoreBucketPrefix?: string;
}

export const BucketSelection = <T extends RestoreBucketState>(
  props: StepProps<T & { restoreAccountId?: string }>
) => {
  const { setState: setStepState } = props;
  const {
    restoreAccountId,
    restoreBucketMode,
    restoreBucketName,
    restoreBucketNameOverride,
  } = props.state;
  const dal = useDAL();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.RestoreBucket
  );
  const { body: s3BucketsResponse } = dal.restore.s3Buckets.list(
    restoreAccountId!
  );
  const setMode = (mode: 'select' | 'manual') =>
    setStepState((state) => ({
      ...state,
      restoreBucketMode: mode,
    }));

  useEffect(() => {
    if (pref?.value?.bucketName) {
      setStepState((state) => ({
        ...state,
        restoreBucketName: pref.value!.bucketName,
        restoreBucketNameOverride: pref.value!.bucketName,
      }));
    }
  }, [pref, setStepState]);

  return (
    <List className='mt-[20px]'>
      <OptionListItem onClick={() => setMode('select')}>
        <FormControlLabel
          checked={restoreBucketMode === 'select'}
          onClick={() => setMode('select')}
          control={<Radio />}
          label='Select an existing S3 bucket'
        />
        <Box
          className={classnames('w-full content', {
            expanded: restoreBucketMode === 'select',
          })}
        >
          <Box className='flex gap-[36px] mb-[20px]'>
            <FormControl size='small' className='w-1/2'>
              <FormLabel>Bucket</FormLabel>
              <Select
                className='h-[58px]'
                size='small'
                value={
                  s3BucketsResponse?.buckets?.find(
                    (x) => x.bucketName === restoreBucketName
                  )?.bucketName || ''
                }
                onChange={(event) =>
                  setStepState((state) => ({
                    ...state,
                    restoreBucketName: event.target.value,
                  }))
                }
              >
                {(s3BucketsResponse?.buckets || [])
                  .sort((a, b) => a.bucketName.localeCompare(b.bucketName))
                  .map((bucket) => (
                    <MenuItem key={bucket.bucketName} value={bucket.bucketName}>
                      <Stack direction={'column'} className='py-[4px]'>
                        <Typography>{bucket.bucketName}</Typography>
                        <Typography variant='body2' className='mt-[4px]'>
                          {bucket.regionName}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </OptionListItem>
      <OptionListItem onClick={() => setMode('manual')}>
        <FormControlLabel
          checked={restoreBucketMode === 'manual'}
          onClick={() => setMode('manual')}
          control={<Radio />}
          label='Enter a Bucket name'
        />
        <Box
          className={classnames('w-full content', {
            expanded: restoreBucketMode === 'manual',
          })}
        >
          <Box className='flex gap-[36px] mb-[20px]'>
            <FormControl size='small' className='w-1/2'>
              <FormLabel>Bucket Name</FormLabel>
              <TransactionalTextField
                initValue={restoreBucketNameOverride || ''}
                onChange={(value) => {
                  setStepState((state) => ({
                    ...state,
                    restoreBucketNameOverride: value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </OptionListItem>
    </List>
  );
};
