import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { type InventoryResourceBackupStatusEnum } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import { BackupStatusIcon } from '@/components/backupStatus/backupStatusIcon';
import {
  InventoryResourceActionsLevels,
  type Action,
} from '@/data/inventory/actions';
import { BackupStatuses } from '@/data/inventory/data';

interface BackupStatusCardProps<T> {
  backupStatus: InventoryResourceBackupStatusEnum;
  actions: Partial<Record<InventoryResourceActionsLevels, Action<T>[]>>;
  entity: T;
}

export function BackupStatusCard<T>(props: BackupStatusCardProps<T>) {
  const { backupStatus, actions, entity } = props;
  const { iconColor, title, fullDescription } = BackupStatuses[backupStatus];

  const moreActions = actions[InventoryResourceActionsLevels.SubMore];
  const primaryActions = actions[InventoryResourceActionsLevels.SubPrimary];
  const secondaryActions = actions[InventoryResourceActionsLevels.SubSecondary];

  return (
    <Box
      margin={'40px 0 24px'}
      padding={'24px 0 24px'}
      border={'1px solid transparent'}
      borderRadius={'16px'}
      sx={{
        background: `linear-gradient(var(--mui-palette-background-paper) 0 0) padding-box,  ${iconColor} border-box`,
      }}
    >
      <Stack marginLeft={'24px'} marginRight={'24px'}>
        <CardHeader actions={moreActions || []} entity={entity} />
        <Stack alignItems={'center'} spacing={'12px'}>
          <BackupStatusIcon backupStatus={backupStatus} />
          <Typography
            data-testid='backup-status-title'
            fontSize={'16px'}
            sx={{ color: 'var(--mui-palette-text-primary) !important' }}
          >
            {title}
          </Typography>
          <Typography
            align={'center'}
            width={'65%'}
            className='text-textSecondary mb-[12px]'
            lineHeight={'20px'}
          >
            {fullDescription}
          </Typography>
          <CardFooter
            primaryActions={primaryActions || []}
            secondaryActions={secondaryActions || []}
            entity={entity}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

function CardHeader<T>(props: { actions: Action<T>[]; entity: T }) {
  const { actions, entity } = props;

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
    >
      {/* todo: Missing text: "Since January 1, 2024" text */}
      <Typography className='font-semibold'>Backup status</Typography>
      {!!actions.length && (
        <OptionMenu
          data-testid='backup-status-card-options'
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={actions.map((action) => ({
            text: action.title || action.tooltip,
            menuItemProps: {
              onClick: () => action.execute(entity),
              disabled: action.disabled?.(),
            },
          }))}
        />
      )}
    </Stack>
  );
}

function CardFooter<T>(props: {
  primaryActions: Action<T>[];
  secondaryActions: Action<T>[];
  entity: T;
}) {
  const { primaryActions, secondaryActions, entity } = props;

  if (!primaryActions.length && !secondaryActions.length) {
    return null;
  }

  return (
    <Fragment>
      <Divider
        flexItem
        variant='middle'
        className='mx-auto mb-[12px] w-[70%]'
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        width='100%'
      >
        {secondaryActions.map((action) => (
          <Button
            key={action.title}
            disabled={action.disabled?.()}
            onClick={() => action.execute(entity)}
            variant={'text'}
          >
            {action.icon && (
              <i
                className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
              />
            )}
            {action.title ?? action.tooltip}
          </Button>
        ))}
        {!!secondaryActions.length && !!primaryActions.length && (
          <Divider
            orientation='vertical'
            flexItem
            variant='middle'
            className='mx-[14px] my-auto h-[24px]'
          />
        )}
        {primaryActions.map((action) => (
          <Button
            disabled={action.disabled?.()}
            key={action.title}
            onClick={() => action.execute(entity)}
            variant={'outlined'}
          >
            {action.icon && (
              <i
                className={`${action.icon} text-[18px] mr-[5px] text-primary align-middle`}
              />
            )}
            {action.title ?? action.tooltip}
          </Button>
        ))}
      </Stack>
    </Fragment>
  );
}
