import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { RestoreDatabaseInputRestoreTypeEnum } from '@repo/api-gw-sdk';
import { useEffect } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { SidePanelPropsTable } from '@/components/panels/instance/tabs/shared';
import { RDSSubnetGroupSelect } from '@/components/regions/rdsSubnetGroupSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { SecurityGroupSelect } from '@/components/regions/securityGroupSelect';
import { CircleImage } from '@/components/shared/circleImage';
import { Tags } from '@/components/tags/tagsComponent';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreRDSState } from '../restoreRDSWizard';

export const RDSConfiguration = (props: StepProps<RestoreRDSState>) => {
  const dal = useDAL();
  const { body: inventoryResourceBody } = dal.inventory.get(
    props.state.resourceId
  );

  useEffect(() => {
    props.setState((state) => ({
      ...state,
      encryptionKeyId: undefined,
      securityGroupId: undefined,
      vpc: undefined,
      rdsSubnetGroupName: undefined,
      regionName: undefined,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state.restoreAccountId]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (
          !props.state.regionName ||
          !props.state.resourceName ||
          !props.state.securityGroupId ||
          !props.state.rdsSubnetGroupName
        ) {
          return false;
        }

        if (props.state.isEncrypted && !props.state.encryptionKeyId) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        const tags = props.state.tags || {};
        tags['eon:restore'] = 'true';

        void dal.restore
          .database(props.state.resourceId, props.state.snapshotId, {
            tags,
            restoreAccountId: props.state.restoreAccountId!,
            restoreType: RestoreDatabaseInputRestoreTypeEnum.Database,
            encryptionKeyId: props.state.encryptionKeyId,
            restoredName: props.state.resourceName,
            restoreRegion: props.state.regionName,
            securityGroup: props.state.securityGroupId,
            subnetGroup: props.state.rdsSubnetGroupName,
          })
          .then(() => {
            props.abort();
            props.onSuccess('Restore job has started!');
          });
      }}
    >
      <Box className='mb-[24px]'>
        <Typography variant='h5'>Configuration</Typography>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <FormControl size='small' className='flex-1'>
            <FormLabel>Database name</FormLabel>
            <TextField
              size='small'
              value={props.state.resourceName || ''}
              onChange={(event) =>
                props.setState((state) => ({
                  ...state,
                  resourceName: event.target.value,
                }))
              }
            />
          </FormControl>
          <RegionSelect
            accountId={props.state.restoreAccountId}
            regionName={props.state.regionName}
            onChange={(region) =>
              props.setState((state) => ({
                ...state,
                regionName: region,
                rdsSubnetGroupName: undefined,
                securityGroupId: undefined,
                vpc: undefined,
                encryptionKeyId: undefined,
              }))
            }
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <RDSSubnetGroupSelect
            accountId={props.state.restoreAccountId}
            regionName={props.state.regionName}
            rdsSubnetGroupName={props.state.rdsSubnetGroupName}
            onChange={(rdsSubnetGroupName, vpc) =>
              props.setState((state) => ({
                ...state,
                rdsSubnetGroupName,
                vpc,
                securityGroupId: undefined,
              }))
            }
          />
          <SecurityGroupSelect
            accountId={props.state.restoreAccountId}
            regionName={props.state.regionName}
            securityGroupId={props.state.securityGroupId}
            vpc={props.state.vpc}
            onChange={(securityGroupId) =>
              props.setState((state) => ({
                ...state,
                securityGroupId,
              }))
            }
          />
        </Stack>
      </Box>
      <Divider />
      {inventoryResourceBody?.databaseProperties?.engine && (
        <>
          <Box className='w-full my-[24px]'>
            <Typography variant='h6'>Database settings</Typography>
            <SidePanelPropsTable className='my-[24px]'>
              <tbody>
                <tr>
                  <td className='props-col'>Engine</td>
                  <td className='capitalize'>
                    <div className='flex items-center'>
                      <CircleImage
                        alt={inventoryResourceBody.databaseProperties.engine}
                        src={
                          ApplicationsLogosWithAliases[
                            inventoryResourceBody.apps[0]
                          ]
                        }
                        className='mr-[8px]'
                      />
                      <Typography color='var(--mui-palette-text-secondary)'>
                        {inventoryResourceBody.databaseProperties.engine}
                      </Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='props-col'>Engine version</td>
                  <td>
                    {inventoryResourceBody.databaseProperties.engineVersion}
                  </td>
                </tr>
              </tbody>
            </SidePanelPropsTable>
          </Box>
          <Divider />
        </>
      )}
      <CardContent className='px-0'>
        {props.state.regionName && (
          <EncryptionComponent
            isEncrypted={props.state.isEncrypted}
            encryptionKeyId={props.state.encryptionKeyId}
            accountId={props.state.restoreAccountId!}
            regionName={props.state.regionName}
            onChange={(encryption) => {
              if (encryption.isEncrypted) {
                props.setState((state) => ({
                  ...state,
                  isEncrypted: true,
                  encryptionKeyId:
                    encryption.mode === 'arn'
                      ? encryption.arn
                      : encryption.encryptionKeyId,
                }));
              } else {
                props.setState((state) => ({
                  ...state,
                  encryptionKeyId: undefined,
                  isEncrypted: false,
                }));
              }
            }}
          />
        )}
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <Tags
          initialTags={{}}
          tags={props.state.tags || {}}
          onChange={(tags) => props.setState((state) => ({ ...state, tags }))}
        />
      </CardContent>
    </StepContainer>
  );
};
