import {
  MenuItem,
  Typography,
  Box,
  Button,
  Popover,
  capitalize,
} from '@mui/material';
import {
  type MinRetentionRule,
  MinRetentionRuleFrequencyEnum,
  type ControlRules,
} from '@repo/api-gw-sdk';
import dayjs from 'dayjs';
import type { Duration } from 'dayjs/plugin/duration';
import { useState, useRef, useMemo } from 'react';

import OptionMenu from '@core/components/option-menu';

import {
  Schedule,
  ScheduleContainer,
  ScheduleSelect,
} from '@/components/schedule/schedule';

import {
  DurationSelector,
  type DurationFragment,
} from './MaximumRetentionEditor';

export const frequencyMapping: {
  windows: string;
  type: MinRetentionRuleFrequencyEnum;
  text: string;
  unit: DurationFragment;
}[] = [
  {
    windows: '0 0 * * *',
    type: MinRetentionRuleFrequencyEnum.Daily,
    text: 'Daily backups',
    unit: 'days',
  },
  {
    windows: '0 0 * * 0',
    type: MinRetentionRuleFrequencyEnum.Weekly,
    text: 'Weekly backups',
    unit: 'weeks',
  },
  {
    windows: '0 0 1 * *',
    type: MinRetentionRuleFrequencyEnum.Monthly,
    text: 'Monthly backups',
    unit: 'months',
  },
  {
    windows: '0 0 1 1 *',
    type: MinRetentionRuleFrequencyEnum.Annual,
    text: 'Annual backups',
    unit: 'years',
  },
];

function ScheduleEditor({
  schedule,
  onChange,
  onDelete,
  onDuplicate,
}: {
  schedule: MinRetentionRule;
  onChange: (schedule: MinRetentionRule) => void;
  onDelete: () => void;
  onDuplicate: () => void;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { unit } =
    frequencyMapping.find(({ type }) => type === schedule.frequency) ||
    frequencyMapping[0];

  const duration: Duration = useMemo(
    () =>
      dayjs.duration({
        days: schedule.minimumRetention || 1,
      }),
    [schedule.minimumRetention]
  );

  const retentionByFragment = Math.round(
    duration[`as${capitalize(unit)}` as DurationFragment]()
  );

  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='12px'>
        <ScheduleSelect
          value={schedule.frequency}
          onChange={(e) =>
            onChange({
              ...schedule,
              frequency: e.target.value as MinRetentionRuleFrequencyEnum,
            })
          }
          variant='standard'
        >
          {frequencyMapping.map((m) => (
            <MenuItem key={m.type} value={m.type}>
              <Typography variant='body1'>{m.text}</Typography>
            </MenuItem>
          ))}
        </ScheduleSelect>
        <Typography variant='body1'>are retained for at least</Typography>
        <Button ref={anchorRef} onClick={() => setOpen(true)}>
          {retentionByFragment} {unit}
          <i className='material-symbols-arrow-drop-down-rounded text-xl' />
        </Button>
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={() => setOpen(false)}
        >
          <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
            <DurationSelector
              fragment={unit}
              duration={duration}
              onChange={(value) => {
                const minimumRetention = Math.floor(
                  dayjs.duration({ [unit]: value }).asDays()
                );
                onChange({ ...schedule, minimumRetention });
              }}
            />
          </Box>
        </Popover>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            { text: 'Duplicate' },
            {
              text: 'Delete',
            },
          ]}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onOptionSelected={(option: any): void => {
            switch (option.text) {
              case 'Duplicate':
                return onDuplicate();
              case 'Delete':
                return onDelete();
            }
          }}
        />
      </Schedule>
    </ScheduleContainer>
  );
}

export function MinimumRetentionEditor({
  rules,
  onChange,
}: {
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  const schedules = (rules.minimumRetention ?? []).filter((r) => r.enabled);
  const updateSchedule = (schedule: MinRetentionRule, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index] = schedule;
    onChange({
      ...rules,
      minimumRetention: newSchedules,
    });
  };

  return (
    <Box className='my-[12px]'>
      {schedules?.map((schedule, i) => (
        <ScheduleEditor
          key={i}
          schedule={schedule}
          onChange={(schedule) => updateSchedule(schedule, i)}
          onDelete={() =>
            onChange({
              ...rules,
              minimumRetention: schedules.filter((s, j) => j !== i),
            })
          }
          onDuplicate={() =>
            onChange({ ...rules, minimumRetention: [...schedules, schedule] })
          }
        />
      ))}
      <div style={{ marginTop: '16px' }}>
        <Button
          variant='outlined'
          onClick={() =>
            onChange({
              ...rules,
              minimumRetention: [
                ...schedules,
                {
                  enabled: true,
                  minimumRetention: 1,
                  frequency: MinRetentionRuleFrequencyEnum.Daily,
                },
              ],
            })
          }
          sx={{ borderColor: 'transparent' }}
        >
          <i className='material-symbols-add-rounded mr-[8px]' />
          Add another
        </Button>
      </div>
    </Box>
  );
}
