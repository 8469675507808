import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

export const SecurityGroupSelect = ({
  accountId,
  regionName,
  securityGroupId,
  onChange,
  vpc,
}: {
  accountId?: string;
  regionName?: string;
  securityGroupId?: string;
  vpc?: string;
  onChange: (value: string) => void;
}) => {
  if (!accountId || !regionName || !vpc) {
    return <EmptySelect title='Security group' />;
  }

  return (
    <InnerSecurityGroupSelect
      vpc={vpc}
      accountId={accountId}
      regionName={regionName}
      securityGroupId={securityGroupId}
      onChange={onChange}
    />
  );
};

const InnerSecurityGroupSelect = ({
  vpc,
  accountId,
  regionName,
  securityGroupId,
  onChange,
}: {
  accountId: string;
  regionName: string;
  vpc: string;
  securityGroupId?: string;
  onChange: (value: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.securityGroups.list(accountId, regionName, vpc);

  if (!body?.securityGroups.length) {
    return <EmptySelect title='Security group' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Security group</FormLabel>
      <Select
        size='small'
        value={securityGroupId || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {body.securityGroups.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
