import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

export const RegionSelect = ({
  accountId,
  regionName,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  onChange: (region: string) => void;
}) => {
  if (!accountId) {
    return <EmptySelect title='Region' />;
  }

  return (
    <InnerRegionSelect
      accountId={accountId}
      regionName={regionName}
      onChange={onChange}
    />
  );
};

const InnerRegionSelect = ({
  accountId,
  regionName,
  onChange,
}: {
  accountId: string;
  regionName?: string;
  onChange: (region: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.regions.list(accountId);

  if (!body?.regions.length) {
    return <EmptySelect title='Region' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Region</FormLabel>
      <Select
        size='small'
        value={regionName || ''}
        onChange={(event) => onChange(event.target.value)}
      >
        {body.regions.map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
