import { styled } from '@mui/material';

export const StyledPITPopperBottomBar = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
  borderTop: '1px solid var(--mui-palette-divider)',
  paddingTop: '20px',
  '& button': {
    marginLeft: '20px',
  },
});
