import { ListItem, styled } from '@mui/material';

export const OptionListItem = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  alignItems: 'flex-start',
  padding: '16px',
  borderRadius: 'var(--mui-shape-borderRadius)',
  border: '2px solid var(--mui-palette-primary-main)',
  marginBottom: '8px',
  gap: 0,

  '.content': {
    height: 0,
    overflow: 'hidden',

    '> :first-child': {
      marginTop: '20px',
    },

    '&.expanded': {
      height: 'auto',
      cursor: 'default',
    },
  },
});
