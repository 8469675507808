import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Divider,
  TextField,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';

import { Loader } from '@/components/layout/loading';

import { useCreateAccount, type AccountType } from './useCreateAccount';

export const CreateAWSAccountPanel = ({
  accountType,
  onCreated,
  setLoading,
}: {
  accountType: AccountType;
  onCreated: () => void;
  setLoading: (loading: boolean) => void;
}) => {
  const {
    accountId: awsAccountId,
    setAccountId,
    roleName,
    setRoleName,
    validationInProgress,
    isAccountCreated,
    isCreatingAccount,
    hasError,
    retry,
    createAccount,
    validateAccount,
  } = useCreateAccount(accountType, onCreated);

  useEffect(() => {
    setLoading(validationInProgress || isCreatingAccount || hasError);
  }, [hasError, isCreatingAccount, setLoading, validationInProgress]);

  return (
    <Box className='px-[20px]'>
      <>
        <Box className='my-[15px]'>
          Before we begin,{' '}
          <b>
            make sure you are logged in to that account in the current browser
            and can create roles
          </b>
        </Box>
        <Box className='my-[15px]'>
          We&apos;ll take you straight to the AWS console to set Eon&apos;s
          permissions. Everything is ready for you, just jump between the steps,
          confirm, and get back to us.
        </Box>
      </>
      <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
        <FormControl size='small' className='mb-[12px]'>
          <FormLabel>AWS Account ID</FormLabel>
          <TextField
            size='medium'
            inputProps={{
              sx: { height: '36px' },
              maxLength: 12,
              pattern: '[a-z0-9-]*',
              'data-testid': 'account-id',
            }}
            disabled={validationInProgress || isCreatingAccount || hasError}
            value={awsAccountId}
            onChange={(event) => setAccountId(event.target.value)}
            onKeyDown={(event) =>
              event.key === 'Enter' && void validateAccount()
            }
          />
        </FormControl>
        <FormControl size='small' className='mb-[12px]'>
          <FormLabel>Role Name</FormLabel>
          <TextField
            size='medium'
            inputProps={{ style: { height: '36px' } }}
            value={roleName}
            disabled={validationInProgress || isCreatingAccount || hasError}
            onChange={(event) => setRoleName(event.target.value)}
            onKeyDown={(event) =>
              event.key === 'Enter' && void validateAccount()
            }
          />
        </FormControl>
        {!validationInProgress &&
          !isCreatingAccount &&
          !isAccountCreated &&
          !hasError && (
            <Button
              variant='contained'
              className='px-[20px] mt-[20px] w-auto'
              sx={{ height: 48 }}
              data-testid='open-aws-console'
              disabled={!awsAccountId || !roleName}
              onClick={() => void validateAccount()}
            >
              Open AWS Console{' '}
              <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
            </Button>
          )}
        {(validationInProgress || isCreatingAccount) && (
          <Box
            className='flex items-center gap-[8px] ml-[20px] justify-between'
            sx={{ color: 'var(--mui-palette-background-lightGray)' }}
          >
            <Stack alignItems='center' direction='row' gap='12px'>
              <Loader sx={{ width: 20 }} />
              {validationInProgress && <>Waiting for permissions...</>}
              {isCreatingAccount && <>Trying to connect...</>}
            </Stack>
            {!isCreatingAccount && (
              <>
                <Button
                  variant='contained'
                  data-testid='aws-console-done'
                  onClick={() => void createAccount()}
                >
                  I&apos;m Done
                </Button>
              </>
            )}
          </Box>
        )}
        {isAccountCreated && (
          <Box
            className='flex items-center gap-[4px] ml-[20px] h-[48px]'
            sx={{ color: 'var(--mui-palette-success-main)' }}
          >
            <i className='material-symbols-check w-[20px] h-[20px]' /> Account
            Created
          </Box>
        )}
        {hasError && (
          <Box
            className='flex items-center gap-[4px] ml-[20px] h-[48px]'
            sx={{ color: 'var(--mui-palette-error-main)' }}
          >
            <i className='material-symbols-close-rounded w-[20px] h-[20px]' />{' '}
            Something went wrong
            <Divider
              orientation='vertical'
              flexItem
              sx={{
                borderColor: 'var(--mui-palette-background-lightGray)',
              }}
              className='mx-[6px] my-auto h-[20px]'
            />
            <Button variant='contained' onClick={retry}>
              Try Again
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
