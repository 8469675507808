import { Box, Stack, Typography } from '@mui/material';
import type { User } from '@repo/api-gw-sdk';
import { forwardRef } from 'react';

import { useUser } from '@/contexts/useUser';

export const UserCircle = forwardRef(
  ({ name, ...rest }: { name: string | undefined | null }, ref) => {
    return (
      <Box
        {...rest}
        ref={ref}
        className='w-[24px] h-[24px] m-[4px] flex items-center justify-center rounded-full bg-[#29A072]'
      >
        <Typography className='font-semibold text-white capitalize'>
          {(name || '')[0]}
        </Typography>
      </Box>
    );
  }
);

export const UserBadge = ({
  user,
  className,
}: {
  user: User;
  className?: string;
}) => {
  const { user: currentUser } = useUser();

  const isSamlUser = user.userOrigin && user.userOrigin !== 'LOCAL_USER';
  const nameSuffix =
    currentUser?.id === user.id ? ' (you)' : isSamlUser ? ' (SAML)' : '';

  return (
    <Stack direction='row' alignItems='center' className={className}>
      <UserCircle name={user.givenName || user.email} />
      <Stack className='ml-[12px]'>
        <Typography className='mb-[4px]'>
          {user.givenName + nameSuffix}
        </Typography>
        <Typography variant='subtitle1'>{user.email}</Typography>
      </Stack>
    </Stack>
  );
};
