import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import type { Snapshot } from '@repo/api-gw-sdk';

import VaultTag from '@/components/vaults/VaultTag';
import { useRoles } from '@/contexts/useRoles';
import type { BackupVault } from '@/data/vaults/backupVault';
import { isDateValid } from '@/utils/dateTime';

export const SnapshotRow = ({
  snap,
  vault,
  selected,
  onRestore,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  selected: Date | undefined;
  onRestore: () => void;
}) => {
  const { isAuthorized } = useRoles();
  const date = isDateValid(snap.pointInTime)
    ? snap.pointInTime
    : snap.dateCreated;

  return (
    <Stack
      className='mb-[8px] w-full'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      key={snap.id}
    >
      <Stack direction='row' alignItems='center' gap='16px'>
        {vault && <VaultTag vault={vault} />}
        {vault && snap.status === 'PENDING_DISABLED' && (
          <PendingCircle backgroundColor={vault.backgroundColor} />
        )}
        <Typography variant='body1'>
          {(snap.status === 'PENDING_DISABLED' ? 'Started at ' : '') +
            date.toLocaleTimeString()}
        </Typography>
      </Stack>
      {snap.status === 'PENDING_DISABLED' && <BackingUpTooltip />}
      {snap.status === 'COMPLETED' && (
        <Button
          disabled={!selected || !isAuthorized('create:restore_resource')}
          variant='outlined'
          color='primary'
          onClick={() => {
            onRestore();
          }}
        >
          Restore
        </Button>
      )}
    </Stack>
  );
};

export const BackingUpTooltip = () => (
  <Tooltip title='Eon is processing data for this snapshot. Once the backup is complete, restore options will be available.'>
    <Typography
      className='font-semibold px-[12px]'
      color='var(--mui-palette-background-lightGray)'
    >
      Backing up...
    </Typography>
  </Tooltip>
);

export const PendingCircle = ({
  backgroundColor,
}: {
  backgroundColor?: string;
}) => {
  if (!backgroundColor) {
    return null;
  }

  return (
    <Box
      component='span'
      className='p-[2px] rounded-full'
      sx={{ border: `2px solid ${backgroundColor}` }}
    >
      <Box
        component='span'
        className='w-[4px] h-[4px] flex items-center justify-center rounded-full'
        sx={{ backgroundColor: backgroundColor }}
      ></Box>
    </Box>
  );
};
