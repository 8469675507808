import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';

export const RolePermissions = ({
  isProjectRole,
  isBuiltInRole,
  scopes,
  editMode,
  onChange,
  hideUnauthorizedPermissions,
}:
  | {
      isBuiltInRole: boolean;
      isProjectRole: boolean | undefined;
      scopes: string[];
      hideUnauthorizedPermissions?: boolean;
      editMode?: false;
      onChange?: (permission: string, value: boolean) => void;
    }
  | {
      isBuiltInRole: boolean;
      isProjectRole: boolean | undefined;
      scopes: string[];
      editMode: true;
      hideUnauthorizedPermissions?: false;
      onChange: (permission: string, value: boolean) => void;
    }) => {
  const { permissions, isAuthorized } = useRoles();

  const permissionsByCategory = Object.entries(permissions).reduce<
    Record<
      string,
      (Extract<(typeof permissions)[string], unknown> & {
        permission: string;
      })[]
    >
  >((agg, [permission, value]) => {
    agg[value.category] = agg[value.category] || [];
    agg[value.category].push({ permission, ...value });
    return agg;
  }, {});

  return (
    <Stack direction='row' className='w-full' justifyContent='space-evenly'>
      {Object.entries(permissionsByCategory)
        .filter(([category, permissions]) => {
          if (isProjectRole && category === 'Account') {
            return false;
          }

          if (!isBuiltInRole && !isProjectRole && category !== 'Account') {
            return false;
          }

          if (hideUnauthorizedPermissions) {
            return permissions.some((x) =>
              x.scopes.every((x) => isAuthorized(x, scopes))
            );
          }

          return true;
        })
        .map(([category, permissions], index, arr) => {
          const groupedPermissions = permissions.reduce<
            Record<string, typeof permissions>
          >((acc, perm) => {
            acc[perm.group || ''] = acc[perm.group || ''] || [];
            acc[perm.group || ''].push(perm);
            return acc;
          }, {});

          return (
            <React.Fragment key={category}>
              <Stack className='w-full'>
                <Typography
                  className='font-semibold cursor-default'
                  color='currentColor'
                  sx={{
                    margin: '12px 0 40px',
                  }}
                >
                  {category}
                </Typography>
                <Stack>
                  {Object.entries(groupedPermissions).map(
                    ([group, permissions]) => {
                      return (
                        <React.Fragment key={category + group}>
                          {group && (
                            <Typography
                              className='mt-[40px] mb-[24px] cursor-default uppercase'
                              variant='body2'
                              sx={{
                                fontSize: '12px',
                                color: 'currentColor',
                              }}
                            >
                              {group}
                            </Typography>
                          )}
                          {permissions.map((permission) => {
                            const isPermissionAuthorized =
                              permission.scopes.every((x) =>
                                isAuthorized(x, scopes)
                              );

                            if (
                              hideUnauthorizedPermissions &&
                              !isPermissionAuthorized
                            ) {
                              return null;
                            }

                            return (
                              <Stack
                                data-testid='role-permission'
                                direction='row'
                                key={permission.permission}
                                gap='12px'
                                sx={{
                                  '&:hover i': {
                                    display: 'block !important',
                                  },
                                }}
                              >
                                {editMode && (
                                  <FormControlLabel
                                    className='py-[8px]'
                                    control={
                                      <Checkbox
                                        className='mr-[12px]'
                                        checked={isPermissionAuthorized}
                                        onChange={(event, value) =>
                                          onChange(permission.permission, value)
                                        }
                                      />
                                    }
                                    label={permission.permission}
                                  />
                                )}
                                {!editMode && (
                                  <>
                                    <Icon
                                      iconClass={
                                        isPermissionAuthorized
                                          ? 'material-symbols-check'
                                          : 'material-symbols-close-rounded'
                                      }
                                      sx={{
                                        i: {
                                          color: isPermissionAuthorized
                                            ? 'currentColor'
                                            : 'var(--mui-palette-text-disabled)',
                                        },
                                      }}
                                    />

                                    <Typography
                                      className='mt-[8px] cursor-default'
                                      sx={{
                                        color: isPermissionAuthorized
                                          ? 'currentColor'
                                          : 'var(--mui-palette-text-disabled)',
                                      }}
                                    >
                                      {permission.permission}
                                    </Typography>
                                  </>
                                )}
                                {permission.description && (
                                  <Tooltip
                                    className='mt-[8px] hidden'
                                    title={permission.description}
                                    slotProps={{
                                      tooltip: {
                                        sx: {
                                          padding: '16px',
                                        },
                                      },
                                    }}
                                  >
                                    <i className=' material-symbols-info-outline h-[20px] w-[20px] p-[2px]' />
                                  </Tooltip>
                                )}
                              </Stack>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
                </Stack>
              </Stack>
              {index < arr.length - 1 && (
                <Divider
                  orientation='vertical'
                  flexItem
                  className='mx-[40px]'
                />
              )}
            </React.Fragment>
          );
        })}
    </Stack>
  );
};
