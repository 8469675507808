import { Box, Typography } from '@mui/material';
import type { BackupPolicy } from '@repo/api-gw-sdk';

import BackupPolicyCard from '@/app/(dashboard)/settings/backup-policies/BackupPolicyCard';
import useBackupVaults from '@/data/vaults/useBackupVaults';

export const BackupPoliciesTab = ({
  activePolicies,
}: {
  activePolicies: BackupPolicy[];
}) => {
  const { data: vaults } = useBackupVaults();

  return (
    <Box className='px-[40px] mt-[40px]'>
      <Typography className='mb-[24px]'>
        {`${activePolicies.length} backup policies are applied`}
      </Typography>
      {activePolicies.map((policy) => (
        <Box key={policy.id} className='mb-[12px]'>
          <BackupPolicyCard
            policy={policy}
            vaults={vaults}
            defaultExpanded={activePolicies.length === 1}
          />
        </Box>
      ))}
    </Box>
  );
};
