import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Link,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { buildRoleArn } from '@/utils/roles';

import { TransactionalTextField } from '../shared/transactionalTextField';

interface Params {
  accountNumber: string;
  roleName: string;
  accountName: string;
}

const texts = {
  restore: {
    description:
      'Eon will need your permissions to save resources to your account. Use our predefined template for an easy and fast process',
    steps: [
      'Make sure you are logged in in your account in the current browser and have the permissions to create roles.',
      'Use our permissions template of quick and easy definitions. We are asking only for the permissions we need.',
      `Once Eon's role is defined, add your account details`,
    ],
    templateUrl: 'https://www.google.com',
  },
  source: {
    description:
      'Eon will need your permissions to scan resources from your account. Use our predefined template for an easy and fast process',
    steps: [
      'Make sure you are logged in in your account in the current browser and have the permissions to create roles.',
      'Use our permissions template of quick and easy definitions. We are asking only for the permissions we need.',
      `Once Eon's role is defined, add your account details`,
    ],
    templateUrl: 'https://www.google.com',
  },
};

export const AddNewAccountComponent = ({
  type,
  onChange,
}: {
  type: 'restore' | 'source';
  onChange: (params: Params & { roleArn: string }) => void;
}) => {
  const [payload, setPayload] = useState<Params>({
    accountNumber: '',
    roleName: '',
    accountName: '',
  });

  return (
    <Box>
      <Typography variant='h5'>Add a New Account</Typography>
      <Typography className='w-[70%] mt-[5px] leading-[20px]'>
        {texts[type].description}
      </Typography>
      <Box className='pt-[20px]'>
        <Box className='flex my-[30px]'>
          <Typography variant='h5'>01</Typography>
          <Typography className='ml-[36px] w-[60%] leading-[20px]'>
            {texts[type].steps[0]}
          </Typography>
        </Box>
        <Divider />
        <Box className='flex my-[30px]'>
          <Typography variant='h5'>02</Typography>
          <Typography className='ml-[36px] w-[60%] leading-[20px]'>
            {texts[type].steps[1]}
          </Typography>
          <Link href={texts[type].templateUrl} target='_blank'>
            <Button variant='outlined' size='small' className='ml-[50px]'>
              <i className='material-symbols-open-in-new w-[20px] h-[20px] mr-[8px]'></i>
              Open Template
            </Button>
          </Link>
        </Box>
        <Divider />
        <Box className='flex my-[30px]'>
          <Typography variant='h5'>03</Typography>
          <Typography className='ml-[36px] w-[60%] leading-[20px]'>
            {texts[type].steps[2]}
          </Typography>
        </Box>
        <Box className='w-[362px] pl-[58px]'>
          <FormControl size='small' className='w-full'>
            <FormLabel>Account Number</FormLabel>
            <TransactionalTextField
              initValue={payload.accountNumber}
              onChange={(value) => {
                const newPayload = { ...payload, accountNumber: value };
                setPayload(newPayload);
                onChange({
                  ...newPayload,
                  roleArn: buildRoleArn(
                    newPayload.accountNumber,
                    newPayload.roleName
                  ),
                });
              }}
            />
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>Role Name</FormLabel>
            <TransactionalTextField
              initValue={payload.roleName}
              onChange={(value) => {
                const newPayload = { ...payload, roleName: value };
                setPayload(newPayload);
                onChange({
                  ...newPayload,
                  roleArn: buildRoleArn(
                    newPayload.accountNumber,
                    newPayload.roleName
                  ),
                });
              }}
            />
          </FormControl>
          <FormControl size='small' className='w-full mt-[24px]'>
            <FormLabel>Account Name</FormLabel>
            <TransactionalTextField
              initValue={payload.accountName}
              onChange={(value) => {
                const newPayload = { ...payload, accountName: value };
                setPayload(newPayload);
                onChange({
                  ...newPayload,
                  roleArn: buildRoleArn(
                    newPayload.accountNumber,
                    newPayload.roleName
                  ),
                });
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
