import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { SidePanelPropsTable } from '@/components/panels/instance/tabs/shared';
import { CircleImage } from '@/components/shared/circleImage';
import { Tags } from '@/components/tags/tagsComponent';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { restoreMongoAtlasState } from '../restoreMongoAtlasWizard';

export const MongoAtlasConfiguration = (
  props: StepProps<restoreMongoAtlasState>
) => {
  const dal = useDAL();
  const { body: inventoryResourceBody } = dal.inventory.get(
    props.state.resourceId
  );
  const { body: projectsResponse } = dal.restore.atlasProjects.list(
    props.state.restoreAccountId!
  );

  useEffect(() => {
    if (!props.state.projectId && projectsResponse?.projects) {
      props.setState((state) => ({
        ...state,
        projectId: projectsResponse.projects[0]?.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsResponse]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (!props.state.projectId || !props.state.resourceName) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        const tags = props.state.tags || {};
        tags['eon:restore'] = 'true';

        void dal.restore.atlasProjects
          .restore(props.state.resourceId, props.state.snapshotId, {
            tags,
            restoreAccountId: props.state.restoreAccountId!,
            clusterName: props.state.resourceName!,
            atlasProjectId: props.state.projectId!,
          })
          .then(() => {
            props.abort();
            props.onSuccess('Restore job has started!');
          });
      }}
    >
      <Box className='mb-[24px]'>
        <Typography variant='h5'>Configuration</Typography>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <FormControl size='small' className='flex-1'>
            <FormLabel>Project</FormLabel>
            <Select
              size='small'
              value={
                (projectsResponse?.projects && props.state.projectId) || ''
              }
              onChange={(event) =>
                props.setState((state) => ({
                  ...state,
                  projectId: event.target.value,
                }))
              }
            >
              {(projectsResponse?.projects || []).map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FormControl size='small' className='w-full mt-[20px]'>
          <FormLabel>Database name</FormLabel>
          <TextField
            size='small'
            value={props.state.resourceName || ''}
            onChange={(event) =>
              props.setState((state) => ({
                ...state,
                resourceName: event.target.value,
              }))
            }
          />
        </FormControl>
      </Box>
      <Divider />
      {inventoryResourceBody?.databaseProperties?.engine && (
        <>
          <Box className='w-full my-[24px]'>
            <Typography variant='h6'>Database settings</Typography>
            <SidePanelPropsTable className='my-[24px]'>
              <tbody>
                <tr>
                  <td className='props-col'>Engine</td>
                  <td className='capitalize'>
                    <div className='flex items-center'>
                      <CircleImage
                        alt={inventoryResourceBody.databaseProperties.engine}
                        src={
                          ApplicationsLogosWithAliases[
                            inventoryResourceBody.apps[0]
                          ]
                        }
                        className='mr-[8px]'
                      />
                      <Typography color='var(--mui-palette-text-secondary)'>
                        {inventoryResourceBody.databaseProperties.engine}
                      </Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='props-col'>Engine version</td>
                  <td>
                    {inventoryResourceBody.databaseProperties.engineVersion}
                  </td>
                </tr>
              </tbody>
            </SidePanelPropsTable>
          </Box>
          <Divider />
        </>
      )}
      <CardContent className='px-0'>
        <Tags
          initialTags={{}}
          tags={props.state.tags || {}}
          onChange={(tags) => props.setState((state) => ({ ...state, tags }))}
        />
      </CardContent>
    </StepContainer>
  );
};
