import {
  AccountCloudEnum,
  type RestoreVolumeInputVolumeSettings,
} from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { SelectTargetAccount } from './steps/selectTargetAccount';
import { SnapshotConfiguration } from './steps/snapshotConfiguration';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreVolumeState {
  volumeResourceId: string;
  actionType: string;
  resourceIdForSnapshots: string;
  snapshotId?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
  regionName: string;
  availabilityZone?: string;
  tags?: Record<string, string>;
  volumeSettings?: RestoreVolumeInputVolumeSettings;
  encryptionKeyId?: string;
  isEncrypted: boolean;
}

const restoreVolumeFlow: Step<RestoreVolumeState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'SnapshotConfigurationStep',
      Component: SnapshotConfiguration,
    },
  ],
};

export const RestoreVolumeWizard = ({
  instanceId,
  snapshotId,
  volumeId,
  volumeRegion,
  actionType,
}: {
  instanceId: string;
  snapshotId: string;
  volumeId: string;
  volumeRegion: string;
  actionType: 'snapshot' | 'volume';
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: instanceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<RestoreVolumeState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreVolumeFlow]}
      initialState={{
        volumeResourceId: volumeId,
        actionType,
        resourceIdForSnapshots: instanceId,
        regionName: volumeRegion,
        snapshotId: snapshotId,
        isEncrypted: true,
        restoreAccountCloudProvider: AccountCloudEnum.Aws,
      }}
      title={
        actionType === 'snapshot' ? 'Convert to EBS Snapshot' : 'Restore Volume'
      }
      stepperLabels={[
        // 'Point in Time',
        'Restore Account',
        'Configuration',
      ]}
    />
  );
};
