import { Box, Stack, styled, Tooltip, Typography } from '@mui/material';
import { type InventoryResource } from '@repo/api-gw-sdk';
import { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionDivider,
  AccordionSummary,
} from '@/components/accordion';
import { CircleImage } from '@/components/shared/circleImage';
import { Icon } from '@/components/shared/icon';
import { Tag } from '@/components/tag';
import {
  ApplicationsLogosWithAliases,
  Environments,
  DataClasses,
} from '@/data/inventory/data';

import { AppsEditor } from './classificationPanels/appsEditor';
import { AppsViewer } from './classificationPanels/appsViewer';
import { DataClassEditor } from './classificationPanels/dataClassEditor';
import { DataClassViewer } from './classificationPanels/dataClassViewer';
import { EnvironmentEditor } from './classificationPanels/environmentEditor';
import { EnvironmentViewer } from './classificationPanels/environmentViewer';
import { RestartApplicationDetection } from './classificationPanels/restartApplicationDetection';
import { RestartAutomaticDataClassClassification } from './classificationPanels/restartAutomaticDataClassification';
import { RestartAutomaticEnvironmentDetection } from './classificationPanels/restartAutomaticEnvironmentDetection';

const LeftPaddedAccordionDetails = styled(AccordionDetails)(() => ({
  paddingRight: 0,
}));

export function Classifications({
  entity,
}: {
  entity: InventoryResource | undefined;
}) {
  const [dataClassEditorOpen, setDataClassEditorOpen] = useState(false);
  const [restartDataClassEditorOpen, setRestartDataClassEditorOpen] =
    useState(false);
  const [environmentEditorOpen, setEnvironmentEditorOpen] = useState(false);
  const [restartEnvironmentEditorOpen, setRestartEnvironmentEditorOpen] =
    useState(false);
  const [applicationsEditorOpen, setApplicationsEditorOpen] = useState(false);
  const [restartApplicationsEditorOpen, setRestartApplicationsEditorOpen] =
    useState(false);

  const [dataClassesExpanded, setDataClassesExpanded] = useState(false);
  const [environmentExpanded, setEnvironmentExpanded] = useState(false);
  const [applicationsExpanded, setApplicationsExpanded] = useState(false);

  const handleDataClassEditorOpen = () => {
    setDataClassEditorOpen(true);
  };
  const handleDataClassEditorClose = () => {
    setDataClassEditorOpen(false);
  };
  const handleRestartClassificationModalOpen = () => {
    setRestartDataClassEditorOpen(true);
  };
  const handleRestartClassificationModalClose = () => {
    setRestartDataClassEditorOpen(false);
  };
  const handleEnvironmentEditorOpen = () => {
    setEnvironmentEditorOpen(true);
  };
  const handleEnvironmentEditorClose = () => {
    setEnvironmentEditorOpen(false);
  };
  const handleRestartEnvironmentEditorOpen = () => {
    setRestartEnvironmentEditorOpen(true);
  };
  const handleRestartEnvironmentEditorClose = () => {
    setRestartEnvironmentEditorOpen(false);
  };
  const handleApplicationsEditorOpen = () => {
    setApplicationsEditorOpen(true);
  };
  const handleApplicationsEditorClose = () => {
    setApplicationsEditorOpen(false);
  };
  const onResumeApplicationClassification = () => {
    setRestartApplicationsEditorOpen(true);
  };
  const handleRestartApplicationsEditorClose = () => {
    setRestartApplicationsEditorOpen(false);
  };

  if (!entity) {
    return null;
  }
  // There are cases (e.g. search) where we show resource details without having the resource details, in this case we just
  // hide the classifications panel
  if (!entity.apps || !entity.dataClasses || !entity.environment) {
    return null;
  }

  const apps = (entity.apps ?? []).slice(0, 7);
  if (entity.apps?.length && entity.apps.length > 7) {
    apps.push(`${entity.apps.length - 7} more...`);
    window.reportError(
      new RangeError(
        `The user has installed many applications on the resource ${
          entity.resourceId
        }:${entity.accountId}`
      )
    );
  }

  return (
    <>
      <Accordion
        defaultExpanded={dataClassesExpanded}
        onChange={(_: unknown, expanded: boolean) =>
          setDataClassesExpanded(expanded)
        }
      >
        <AccordionSummary data-testid='side-panel-classifications-data-classes'>
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Data classes</Typography>
            <Stack gap='8px' direction='row'>
              {entity?.dataClasses?.map((x) => (
                <Tooltip key={x} title={DataClasses[x]?.title}>
                  <Tag variant='filled' content={x} />
                </Tooltip>
              ))}
            </Stack>
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <DataClassViewer
            entity={entity}
            setDataClassOpen={handleDataClassEditorOpen}
            setRestartDetectionModalOpen={handleRestartClassificationModalOpen}
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <AccordionDivider />
      <Accordion
        defaultExpanded={false}
        onChange={(_: unknown, expanded: boolean) =>
          setEnvironmentExpanded(expanded)
        }
      >
        <AccordionSummary data-testid='side-panel-classifications-environment'>
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Environment</Typography>
            {!!entity.environment && (
              <Box
                className='flex'
                sx={{
                  opacity: environmentExpanded ? '0' : '1',
                  transition: 'linear 0.1s',
                }}
              >
                <Typography className='font-light'>
                  {Environments[entity.environment].title}
                </Typography>
              </Box>
            )}
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <EnvironmentViewer
            entity={entity}
            setEnvironmentDetectionOff={handleEnvironmentEditorOpen}
            restartEnvironmentDetection={handleRestartEnvironmentEditorOpen}
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <AccordionDivider />
      <Accordion
        defaultExpanded={false}
        onChange={(_: unknown, expanded: boolean) =>
          setApplicationsExpanded(expanded)
        }
      >
        <AccordionSummary data-testid='side-panel-classifications-applications'>
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Apps</Typography>
            <Box
              className='flex'
              sx={{
                opacity: applicationsExpanded ? '0' : '1',
                transition: 'linear 0.1s',
              }}
            >
              {apps?.map((v, i) =>
                i === 7 ? ( // 3 is the index of the 'more' tag
                  <Tooltip title={v} key={i}>
                    <Icon
                      iconClass='material-symbols-more-horiz'
                      sx={{ width: '30px', height: '30px', marginLeft: '4px' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={v} key={i}>
                    <CircleImage
                      alt={v}
                      src={ApplicationsLogosWithAliases[v]}
                    />
                  </Tooltip>
                )
              )}
            </Box>
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <AppsViewer
            entity={entity}
            onEditApplications={handleApplicationsEditorOpen}
            onResumeApplicationClassification={
              onResumeApplicationClassification
            }
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <DataClassEditor
        open={dataClassEditorOpen}
        onClose={handleDataClassEditorClose}
        entities={[entity]}
      />
      <RestartAutomaticDataClassClassification
        open={restartDataClassEditorOpen}
        onClose={handleRestartClassificationModalClose}
        entities={[entity]}
      />
      <EnvironmentEditor
        open={environmentEditorOpen}
        onClose={handleEnvironmentEditorClose}
        entities={[entity]}
      />
      <RestartAutomaticEnvironmentDetection
        open={restartEnvironmentEditorOpen}
        onClose={handleRestartEnvironmentEditorClose}
        entities={[entity]}
      />
      <AppsEditor
        open={applicationsEditorOpen}
        onClose={handleApplicationsEditorClose}
        entities={[entity]}
      />
      <RestartApplicationDetection
        open={restartApplicationsEditorOpen}
        onClose={handleRestartApplicationsEditorClose}
        entities={[entity]}
      />
    </>
  );
}
