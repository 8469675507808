import { Box, CardContent, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { AvailabilityZoneSelect } from '@/components/regions/availabilityZoneSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { Tags } from '@/components/tags/tagsComponent';
import { VolumeSettingsSection } from '@/components/volumes/volumeSettings';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { type RestoreVolumeState } from '../restoreVolumeWizard';

export const SnapshotConfiguration = (props: StepProps<RestoreVolumeState>) => {
  const dal = useDAL();
  const { body: snapshotResponse } = dal.inventory.snapshots.get(
    props.state.snapshotId!
  );

  const volumeProperties = snapshotResponse?.properties?.volumeProperties?.find(
    (x) => x.volumeId === props.state.volumeResourceId
  );

  useEffect(() => {
    if (volumeProperties) {
      props.setState((state) => ({
        ...state,
        tags: volumeProperties.tags || {},
        regionName: volumeProperties.region,
        availabilityZone: volumeProperties.availabilityZone,
        volumeSettings: volumeProperties.volumeSettings,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotResponse]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={props.back}
      canGoNext={() => {
        if (!props.state.regionName) {
          return false;
        }

        if (props.state.isEncrypted && !props.state.encryptionKeyId) {
          return false;
        }

        if (
          props.state.actionType === 'volume' &&
          !props.state.availabilityZone
        ) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        const tags = props.state.tags || {};
        tags['eon:restore'] = 'true';

        if (props.state.actionType === 'snapshot') {
          void dal.convert
            .volume(
              props.state.resourceIdForSnapshots,
              props.state.volumeResourceId,
              {
                tags,
                regionName: props.state.regionName ?? '',
                snapshotId: props.state.snapshotId!,
                restoreAccountId: props.state.restoreAccountId!,
                encryptionKeyId: props.state.encryptionKeyId,
                snapshotEncryptionKeyId: props.state.isEncrypted
                  ? props.state.encryptionKeyId
                  : undefined,
              }
            )
            .then(() => {
              props.abort();
            });
        } else if (props.state.actionType === 'volume') {
          void dal.restore
            .volume(
              props.state.resourceIdForSnapshots,
              props.state.volumeResourceId,
              {
                tags,
                snapshotId: props.state.snapshotId!,
                restoreAccountId: props.state.restoreAccountId!,
                encryptionKeyId: props.state.encryptionKeyId,
                availabilityZone: props.state.availabilityZone!,
                volumeSettings: props.state.volumeSettings!,
                volumeEncryptionKeyId: props.state.isEncrypted
                  ? props.state.encryptionKeyId
                  : undefined,
              }
            )
            .then(() => {
              props.abort();
              props.onSuccess('Restore job has started!');
            });
        }
      }}
    >
      <Box className='mb-[24px]'>
        <Typography variant='h5'>Configuration</Typography>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <RegionSelect
            accountId={props.state.restoreAccountId}
            regionName={props.state.regionName}
            onChange={(region) =>
              props.setState((state) => ({
                ...state,
                regionName: region,
                availabilityZone: undefined,
                encryptionKeyId: undefined,
              }))
            }
          />
          {props.state.actionType === 'volume' && (
            <AvailabilityZoneSelect
              accountId={props.state.restoreAccountId}
              regionName={props.state.regionName}
              availabilityZone={props.state.availabilityZone}
              onChange={(availabilityZone) =>
                props.setState((state) => ({
                  ...state,
                  availabilityZone,
                }))
              }
            />
          )}
        </Box>
      </Box>
      {props.state.actionType === 'volume' && (
        <>
          <Divider />
          <CardContent className='px-0'>
            <VolumeSettingsSection
              initialSettings={volumeProperties?.volumeSettings}
              settings={props.state.volumeSettings}
              onChange={(volumeSettings) =>
                props.setState((state) => ({ ...state, volumeSettings }))
              }
            />
          </CardContent>
        </>
      )}
      <Divider />
      <CardContent className='px-0'>
        {props.state.regionName && (
          <EncryptionComponent
            isEncrypted={props.state.isEncrypted}
            encryptionKeyId={props.state.encryptionKeyId}
            accountId={props.state.restoreAccountId!}
            regionName={props.state.regionName}
            onChange={(encryption) => {
              if (encryption.isEncrypted) {
                props.setState((state) => ({
                  ...state,
                  isEncrypted: true,
                  encryptionKeyId:
                    encryption.mode === 'arn'
                      ? encryption.arn
                      : encryption.encryptionKeyId,
                }));
              } else {
                props.setState((state) => ({
                  ...state,
                  encryptionKeyId: undefined,
                  isEncrypted: false,
                }));
              }
            }}
          />
        )}
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <Tags
          initialTags={volumeProperties?.tags || {}}
          tags={props.state.tags || {}}
          onChange={(tags) => props.setState((state) => ({ ...state, tags }))}
        />
      </CardContent>
    </StepContainer>
  );
};
