import { List, ListItemButton, Radio, Typography } from '@mui/material';
import type { AccountCloudEnum } from '@repo/api-gw-sdk';

import { Loader } from '@/components/layout/loading';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';

export const SelectTargetAccount = <T,>(
  props: StepProps<
    T & {
      restoreAccountId?: string;
      restoreAccountCloudProvider?: AccountCloudEnum;
    }
  >
) => {
  const dal = useDAL();
  const { body } = dal.cloudAccounts.restore.list();

  if (!body) {
    return <Loader />;
  }

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 2}
      onBackClick={props.back}
      canGoNext={() => !!props.state.restoreAccountId}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
    >
      <Typography variant='h5'>Set a Target Account</Typography>
      <Typography component={'span'}>
        Select from the following accounts or add a new one:
      </Typography>
      <List className='mt-[20px] mb-[10px]'>
        {body.accounts
          .filter(
            (account) =>
              !props.state.restoreAccountCloudProvider ||
              account.cloud === props.state.restoreAccountCloudProvider
          )
          .map((account) => (
            <ListItemButton
              key={account.id}
              sx={{
                border: '2px solid var(--mui-palette-primary-main)',
                marginBottom: '8px',
              }}
              onClick={() =>
                props.setState((state) => ({
                  ...state,
                  restoreAccountId: account.id,
                }))
              }
              className='flex'
            >
              <Radio
                className='p-0'
                checked={props.state.restoreAccountId === account.id}
                value={account.id}
              />
              <Typography className='flex-grow'>{account.name}</Typography>
              {account.providerAccountId !== account.name && (
                <Typography component={'span'}>
                  {account.providerAccountId}
                </Typography>
              )}
            </ListItemButton>
          ))}
      </List>
    </StepContainer>
  );
};
