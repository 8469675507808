import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { RestoreBucketState } from './bucketSelection';
import { S3Configuration } from './steps/s3Configuration';
import { SelectTargetAccount } from './steps/selectTargetAccount';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreS3State extends RestoreBucketState {
  resourceId: string;
  snapshotId: string;
  restoreBucketPrefix?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
}

const restoreS3Flow: Step<RestoreS3State> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'S3Configuration',
      Component: S3Configuration,
    },
  ],
};

export const RestoreS3Wizard = ({
  resourceId,
  snapshotId,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<RestoreS3State>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreS3Flow]}
      initialState={{
        snapshotId,
        resourceId,
        restoreBucketMode: 'select',
        restoreAccountCloudProvider: AccountCloudEnum.Aws,
      }}
      title={'Restore S3'}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
