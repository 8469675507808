import { Stack, Tooltip, Typography } from '@mui/material';

import { Icon } from '@/components/shared/icon';
import { Tag } from '@/components/tag';

export const EonTagRemark = () => (
  <Stack alignItems='center' direction='row'>
    <Typography className='mr-[12px]'>{`Eon's restoration tag will be added`}</Typography>
    <Tag variant='outlined' content='eon:restore=true' />
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            padding: '16px',
          },
        },
      }}
      title={`By using the tag, you can identify the resources you've restored. Additionally, it allows us to skip these resources when backing up`}
    >
      <Icon iconClass='material-symbols-help-outline-rounded' />
    </Tooltip>
  </Stack>
);
