import { Box, Typography } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';

import { useRoles } from '@/contexts/useRoles';
import { Environments } from '@/data/inventory/data';

import { AutoClassification } from './autoClassiifcationButtons';

export function EnvironmentViewer({
  entity,
  setEnvironmentDetectionOff,
  restartEnvironmentDetection,
}: {
  entity: InventoryResource;
  setEnvironmentDetectionOff: () => void;
  restartEnvironmentDetection: () => void;
}) {
  const { isAuthorized } = useRoles();

  if (entity.isEnvironmentOverridden) {
    return (
      <>
        <AutoClassification
          testId='environment-auto-classification-buttons-override'
          textOn=''
          isAutoClassificationOn={false}
          restartDetectionClicked={() => restartEnvironmentDetection()}
          editOverridesClicked={() => setEnvironmentDetectionOff()}
          setEditorOpen={() => setEnvironmentDetectionOff()}
          disabled={!isAuthorized('update:environment_classification')}
        />
        <Box className='mt-6' sx={{ color: 'var(--mui-palette-primary-main)' }}>
          <Typography className='font-light'>
            {Environments[entity?.environment ?? '']?.title ?? 'Not Detected'}
          </Typography>
        </Box>
      </>
    );
  }

  const textOn = entity.environment
    ? 'This environment was detected:'
    : 'No environment was detected';

  return (
    <>
      <AutoClassification
        testId='environment-auto-classification-buttons-auto'
        textOn={textOn}
        isAutoClassificationOn={true}
        restartDetectionClicked={() => restartEnvironmentDetection()}
        editOverridesClicked={() => setEnvironmentDetectionOff()}
        setEditorOpen={() => setEnvironmentDetectionOff()}
        disabled={!isAuthorized('update:environment_classification')}
      />
      {!!entity.environment && (
        <Box className='mt-6' sx={{ color: 'var(--mui-palette-primary-main)' }}>
          <Typography className='font-light'>
            {Environments[entity.environment].title}
          </Typography>
        </Box>
      )}
    </>
  );
}
