import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  InventoryResourceResourceTypeEnum,
  type DatabaseProperties,
  type Snapshot,
} from '@repo/api-gw-sdk';
import { useMemo } from 'react';

import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { volumePresets } from '@/components/volumes/volumeSettings';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';
import { convertToBytes, fileSizeFormatter } from '@/utils/fileSizeFormatter';

import { Panels } from '../../panels';

export const DatabaseSection = ({
  resourceId,
  db,
  resourceType,
}: {
  resourceId: string;
  db: DatabaseProperties;
  resourceType: InventoryResourceResourceTypeEnum;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const { isAuthorized } = useRoles();
  const dal = useDAL();
  const { body } = dal.inventory.snapshots.list(resourceId, 'eon', 0, 100);
  const { data: vaults } = useBackupVaults();

  const latestSnapshot = useMemo(
    () =>
      (body?.data || []).reduce<Snapshot | undefined>((agg, x) => {
        if (x.status === 'COMPLETED' && isDateValid(x.pointInTime)) {
          if (
            !agg?.pointInTime ||
            agg.pointInTime.getTime() < x.pointInTime.getTime()
          ) {
            return x;
          }
        }

        return agg;
      }, undefined),
    [body?.data]
  );

  const vault =
    latestSnapshot && vaults?.find((v) => v.id === latestSnapshot.vaultId);

  return (
    <>
      {vault && (
        <Stack
          className='w-full'
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {isDateValid(latestSnapshot.pointInTime) && (
            <Stack direction={'row'} alignItems={'center'}>
              <SnapshotIndicator
                backgroundColor={vault.backgroundColor!}
                title={vault.name}
              />
              <Typography
                className='ml-[12px]'
                sx={{ fontWeight: '400 !important' }}
              >
                {`Last backed up on ${dayjs(latestSnapshot.pointInTime).format('LL')}`}
              </Typography>
            </Stack>
          )}
          <Button
            disabled={!isAuthorized('create:restore_resource')}
            variant='outlined'
            className='my-[16px]'
            onClick={() =>
              setComponent({
                panel:
                  resourceType ===
                  InventoryResourceResourceTypeEnum.AtlasCluster
                    ? Panels.RestoreMongoAtlasWizard
                    : Panels.RestoreRDSWizard,
                props: {
                  resourceId,
                  snapshotId: latestSnapshot.id,
                  sourceRegion: vaults.find(
                    (x) => x.id === latestSnapshot.vaultId
                  )?.region,
                },
              })
            }
          >
            <i
              className={`material-symbols-settings-backup-restore-rounded text-[18px] mr-[8px] text-primary align-middle`}
            />
            Restore
          </Button>
        </Stack>
      )}
      <Table
        className='w-full'
        sx={{
          td: {
            padding: '4px 0',
            borderBottom: 'none',
          },

          '& .props-col': {
            verticalAlign: 'top',
            fontWeight: '400',
            width: '180px',
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell className='props-col'>Database name</TableCell>
            <TableCell>{db.dbName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Cluster</TableCell>
            <TableCell>{db.clusterId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Instance class</TableCell>
            <TableCell>{db.instanceClass}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Multi AZ</TableCell>
            <TableCell className='capitalize'>
              {Boolean(db.multiAZ).toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Publicly accessible</TableCell>
            <TableCell className='capitalize'>
              {Boolean(db.publiclyAccessible).toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Type</TableCell>
            <TableCell>{volumePresets[db.storageType]?.displayName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Size</TableCell>
            <TableCell>
              {fileSizeFormatter(convertToBytes(db.storageSize, 'GB'))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>IOPS</TableCell>
            <TableCell>{db.storageIops}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Throughput</TableCell>
            <TableCell>{`${db.storageThroughput} MB/s`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
