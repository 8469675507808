import type { ServiceAccount } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  UpsertServiceAccountFlow,
  type UpsertServiceAccountState,
} from './upsertServiceAccountFlow';

export interface UpsertServiceAccountSidePanelProps {
  entity: ServiceAccount;
  onSave: (serviceAccount: ServiceAccount) => void;
  onAbort: () => void;
}

export function ServiceAccountPanel(props: UpsertServiceAccountSidePanelProps) {
  const save = (state: UpsertServiceAccountState) => {
    props.onSave(state);
    return true;
  };

  const validate = (state: UpsertServiceAccountState) => {
    if (
      !state.givenName ||
      state.givenName.trim() === '' ||
      state.givenName.length < 4
    ) {
      return 'Name is required';
    }
  };

  return (
    <Wizard<UpsertServiceAccountState>
      onAbort={props.onAbort}
      onFinish={save}
      validate={validate}
      flow={[UpsertServiceAccountFlow]}
      initialState={{
        ...props.entity,
      }}
      title={
        props.entity.id ? `Edit Service Account` : `Create Service Account`
      }
    />
  );
}
