import type { ServiceAccount } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { ServiceAccountsNameSelection } from './serviceAccountsNameSelection';
import { ServiceAccountsScopesSelection } from './serviceAccountsScopesSelection';
import { ServiceAccountsTokenReveal } from './serviceAccountsTokenReveal';

export type UpsertServiceAccountState = ServiceAccount & {
  clientSecret?: string;
  clientId?: string;
};

export const UpsertServiceAccountFlow: Step<UpsertServiceAccountState> = {
  name: 'Name Selection',
  Component: ServiceAccountsNameSelection,
  next: [
    {
      name: 'Scopes Selection',
      Component: ServiceAccountsScopesSelection,
      next: [
        {
          name: 'Token Reveal',
          Component: ServiceAccountsTokenReveal,
          next: [],
        },
      ],
    },
  ],
};
