import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';
import { BucketSelection } from '../bucketSelection';
import type { RestoreS3State } from '../restoreS3Wizard';

export const S3Configuration = (props: StepProps<RestoreS3State>) => {
  const {
    state: stepState,
    setState: setStepState,
    stepperLabels,
    abort,
    back,
    onSuccess,
  } = props;
  const {
    restoreBucketMode,
    restoreBucketName,
    restoreBucketNameOverride,
    restoreBucketPrefix,
    restoreAccountId,
    snapshotId,
    resourceId,
  } = stepState;
  const dal = useDAL();

  const [error, setError] = useState<string | undefined>();

  const onNextClick = () => {
    setError(undefined);
    const bucketName =
      restoreBucketMode === 'select'
        ? restoreBucketName
        : restoreBucketNameOverride;

    // for saving the bucket name between different restores
    void dal.restore.s3Buckets
      .restore(resourceId, snapshotId, {
        restoreAccountId: restoreAccountId!,
        restoreBucketName: bucketName!,
        restoreBucketPrefix: restoreBucketPrefix,
      })
      .then(() => {
        abort();
        onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore bucket: ${e.message}`);
      });
  };

  return (
    <StepContainer
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      nextButtonText='Start'
      onBackClick={back}
      canGoNext={() => {
        return restoreBucketMode === 'select'
          ? !!restoreBucketName
          : !!restoreBucketNameOverride;
      }}
      navigationComponent={
        error && (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        )
      }
      onNextClick={onNextClick}
    >
      <Box>
        <Typography variant='h5'>Configuration</Typography>
        <BucketSelection {...props} />
        <FormControl size='small' className='w-full mt-[20px]'>
          <FormLabel>Bucket prefix</FormLabel>
          <TextField
            size='small'
            value={restoreBucketPrefix || ''}
            onChange={(event) =>
              setStepState((state) => ({
                ...state,
                restoreBucketPrefix: event.target.value,
              }))
            }
          />
        </FormControl>
      </Box>
    </StepContainer>
  );
};
