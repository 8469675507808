'use client';

import { Slide, styled } from '@mui/material';
import classnames from 'classnames';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { PanelsMap, type PanelTypes } from '@/components/panels/panelsMap';
import { useWorkspace } from '@/contexts/useWorkspace';
import { PanelSize } from '@/contexts/workspaceContext';

export const LeftSidePanel = () => {
  const { leftPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen } = leftPanel;

  return (
    <StyledPanel
      className='border-s-0 min-w-[380px] w-[380px] sticky'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
      position='left'
    />
  );
};

export function getPanelWidth(panelSize: PanelSize | string): string {
  let width = '840px';
  if (panelSize === PanelSize.Small) {
    width = '640px';
  } else if (panelSize === PanelSize.Large) {
    width = '840px';
  } else if (panelSize === PanelSize.Full) {
    width = 'calc(100vw - 59px)';
  } else {
    width = panelSize;
  }
  return width;
}
export const RightSidePanel = () => {
  const { rightPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen, panelSize } = rightPanel;

  const width = getPanelWidth(panelSize);

  return (
    <StyledPanel
      sx={{
        backgroundColor: 'var(--mui-palette-background-paper)',
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.2)',
        width: width,
        borderInlineStart:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
        borderInlineEnd:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
      }}
      className='right-0 border-e-0 fixed'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
      position='right'
    />
  );
};

const SidePanel = ({
  className,
  isOpen,
  setIsOpen,
  Component,
  position,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  Component: PanelTypes | undefined;
  className: string;
  position: 'right' | 'left';
}) => {
  const path = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [path, setIsOpen]);

  const Panel = Component?.panel && PanelsMap[Component.panel];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = Component?.props || ({} as any);
  const show = !!(isOpen && Panel);

  return (
    <Slide
      direction={position === 'right' ? 'left' : 'right'}
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={500}
      easing={{
        enter: 'ease-out',
        exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    >
      <div
        data-testid='side-panel'
        className={classnames('h-dvh flex flex-col z-[5] top-0', className)}
      >
        {Panel && <Panel {...props} />}
      </div>
    </Slide>
  );
};

const StyledPanel = styled(SidePanel)({});
